import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import "./Services.css";
import NMR from "./img/NMR.png";
import DSC from "./img/DSC.png";
import NIR from "./img/NIR.png";
import XPS from "./img/XPS.png";
import MALDI from "./img/MALDI.png";
import SPEC from "./img/SPECIAL.png";
import NEED from "./img/NEEDS.png";
import IR from "./img/IR.png";
import DART from "./img/DART.png";
import MPS from "./img/MPS.png";
import ICP from "./img/ICP.png";
import GCMS from "./img/GCMS.png";
import XRF from "./img/XRF.png";
import UVVIS from "./img/UVVIS.png";
import XRD from "./img/XRD.png";
import LCMS from "./img/LCMS.png";
import SEM from "./img/SEM.png";
import CHNS from "./img/CHNS.png";
import GPC from "./img/GPC.png";
import SIMS from "./img/SIMS.png";
import SPLoS from "./img/SciencePort List of Services.pdf";

class Services extends Component {
    render(){
        try {window.scroll({top: 0, left: 0, behavior: 'smooth'});}
        catch (error) {window.scrollTo(0, 0);}

        return(
            <div className="mb-5">
                <h2 className="text-left">Science Port | <span className="glow"> Services</span></h2>
                <div className="row justify-content-center mb-5">
                    <div className="col-lg-6 mb-5">
                        <ul id="grid" className="clear">
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Nuclear_magnetic_resonance"><div title="Nuclear Magnetic Resonance" className="hexagon black"><img className="img-fluid" src={NMR} alt="NMR"/><div className="overlay"></div></div></a></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Differential_scanning_calorimetry"><div title="Differential Scanning Calorimetry" className="hexagon black"><img className="img-fluid" src={DSC} alt="DSC"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Near-infrared_spectroscopy"><div title="Near Infrared Sectroscopy" className="hexagon black"><img className="img-fluid" src={NIR} alt="NIR"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Photoemission_spectroscopy"><div title="X-ray Photoelectron Spectroscopy" className="hexagon"><img className="img-fluid" src={XPS} alt="XPS"/><div className="overlay"></div></div></a></li>
                            <li><div className=""></div></li>
                            
                            <li><div className=""></div></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Matrix-assisted_laser_desorption/ionization"><div title="Matrix Assisted Laser Desorption Ionisation - mass spectrometry" className="hexagon black"><img className="img-fluid" src={MALDI} alt="MALDI"/><div className="overlay"></div></div></a></li>
                            <li><div title="Special Requirements" className="hexagon teal"><img className="img-fluid" src={SPEC} alt="SPEC"/><div className="overlay"></div></div></li>
                            <li><div title="Problem Solving" className="hexagon"><img className="img-fluid" src={NEED} alt="NEED"/><div className="overlay"></div></div></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Infrared_spectroscopy"><div title="Infrared Spectroscopy" className="hexagon teal"><img className="img-fluid" src={IR} alt="IR"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Direct_analysis_in_real_time"><div title="Direct Analysis in Real Time - mass spectrometry" className="hexagon"><img className="img-fluid" src={DART} alt="DART"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Atomic_emission_spectroscopy"><div title="Microwave Plasma Spectrosopy" className="hexagon"><img className="img-fluid" src={MPS} alt="MPS"/><div className="overlay"></div></div></a></li>
                            
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Inductively_coupled_plasma_mass_spectrometry"><div title="Inductive Coupled Plasma spectroscopy" className="hexagon black"><img className="img-fluid" src={ICP} alt="ICP"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Gas_chromatography%E2%80%93mass_spectrometry"><div title="Gas chromatography - mass spectroscopy" className="hexagon black"><img className="img-fluid" src={GCMS} alt="GCMS"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/X-ray_fluorescence"><div title="X-Ray Fluorescence spectroscopy" className="hexagon"><img className="img-fluid" src={XRF} alt="XRF"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Ultraviolet%E2%80%93visible_spectroscopy"><div title="UltraViolet - Visible spectroscopy" className="hexagon teal"><img className="img-fluid" src={UVVIS} alt="UVVIS"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://wiki.anton-paar.com/en/x-ray-diffraction-xrd/"><div title="X-Ray Diffraction" className="hexagon teal"><img className="img-fluid" src={XRD} alt="XRD"/><div className="overlay"></div></div></a></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            
                            <li><div className=""></div></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Liquid_chromatography%E2%80%93mass_spectrometry"><div title="Liquid Chromatography - Mass Spectrometry" className="hexagon teal"><img className="img-fluid" src={LCMS} alt="LCMS"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Scanning_electron_microscope"><div title="Scanning Electron Microscopy" className="hexagon"><img className="img-fluid" src={SEM} alt="SEM"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="http://cdex-science.jamstec.go.jp/chikyu-wiki/index.php/CNS_Elemental_Analysis"><div title="Carbon, Hydrogen, Nitrogen and Sulfur elemental analysis" className="hexagon"><img className="img-fluid" src={CHNS} alt="CHNS"/><div className="overlay"></div></div></a></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Gel_permeation_chromatography"><div title="Gel Permeation Chromatography" className="hexagon teal"><img className="img-fluid" src={GPC} alt="GPC"/><div className="overlay"></div></div></a></li>
                            <li><a target="blank" href="https://en.wikipedia.org/wiki/Secondary_ion_mass_spectrometry"><div title="Secondary Ion Mass Spectrometry" className="hexagon teal"><img className="img-fluid" src={SIMS} alt="SIMS"/><div className="overlay"></div></div></a></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                            <li><div className=""></div></li>
                        </ul>
                    </div>
                    
                    <div className="col-11 col-lg-6 mt-lg-5 mx-auto mb-5">                       
                        <div className="col-11 mx-auto mx-lg-0">
                        <Link className="px-2" to="/contact">
                            <button type="button" className="btn btn-lg btn-outline-warning mb-4">Get in Touch</button>
                        </Link>
                        <Link className="px-2" to="/about">
                            <button type="button" className="btn btn-lg btn-outline-info mb-4">About Us</button>                            
                        </Link>
                        
                        </div>

                        <div className="col-11 mx-auto  mx-lg-0 mb-5">
                        <a href = {SPLoS} target = "blank"><button type="button" className="btn btn-lg btn-outline-secondary mb-4">PDF - SciencePort services list</button></a>
                        <ul  style={{fontSize: '1.2rem'}} className="text-left highlightText">
                            <li>Chemical Characterisation (REACH Article 26)<a className="btn btn-link" href="https://reachonline.eu/REACH/EN/REACH_EN/article26.html" target="blank"><span className="badge badge-info">wiki</span></a><a className="btn btn-link" href="https://echa.europa.eu/regulations/reach/understanding-reach" target="blank"><span className="badge badge-info">wiki</span></a></li>
                            <li>Sameness Testing <a className="btn btn-link" href="https://echa.europa.eu/support/registration/finding-your-co-registrants/establishing-substance-sameness" target="blank"><span className="badge badge-info">wiki</span></a></li>
                            <li>Confirmation of Chemical Structures<a className="btn btn-link" href="https://en.wikipedia.org/wiki/Chemical_structure" target="blank"><span className="badge badge-info">wiki</span></a></li>
                            <li>Structural Elucidation<a className="btn btn-link" href="https://en.wikipedia.org/?title=Structure_elucidation&redirect=no" target="blank"><span className="badge badge-info">wiki</span></a></li>
                            <li>Identification of Impurities<a className="btn btn-link" href="https://en.wikipedia.org/wiki/Impurity" target="blank"><span className="badge badge-info">wiki</span></a></li>
                            <li>Very Wide Range of Available Equipment</li>
                            <li>Chemometric Data Evaluation<a className="btn btn-link" href="https://en.wikipedia.org/wiki/Chemometrics" target="blank"><span className="badge badge-info">wiki</span></a></li>
                            <li>Analytical Method Development<a className="btn btn-link" href="https://en.wikipedia.org/wiki/Analytical_technique" target="blank"><span className="badge badge-info">wiki</span></a></li>
                            <li>Formulation and Stability Assessments<a className="btn btn-link" href="https://en.wikipedia.org/wiki/Chemical_stability" target="blank"><span className="badge badge-info">wiki</span></a></li>
                            <li>Consultancy Services to Resolve Your Analytical Issues</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;
