import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import cleanBakers from "./img/cleanbakers.jpg";

class Pricing extends Component {
    render(){
        try {window.scroll({top: 0, left: 0, behavior: 'smooth'});}
        catch (error) {window.scrollTo(0, 0);}

        return(
            <div>
                <h2 className="text-left">Science Port | <span className="glow"> Pricing</span></h2>
                <div className="row mt-5 mb-5 mx-auto">
                    <div className="col-lg-8 mx-auto">                            
                        <img className="rounded border border-white img-thumbnail img-fluid" src={cleanBakers} alt="Clean Bakers"/>                        
                    </div>
                    
                    <div className="col-lg-6 h4 mt-5 mb-5 highlightText mx-auto">
                        As no two projects are the same, we need to know more about yours, before making any offer.
                    </div>
                    
                    <div className="col-lg-8 h4 mt-2 mb-2 mx-auto">
                        <Link className="" to="/contact">
                            <button type="button" className="btn btn-lg btn-outline-warning mb-5">Get in Touch</button>
                        </Link>    
                    </div>                    

                    <div className="col-lg-8 h4 mb-5 mx-auto wallOfText">    
                        We do our best to find a proper <span className='highlightText'>solution</span> for you!
                    </div>
                </div>
            </div>
        );
    }
}

export default Pricing;
