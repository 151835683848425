import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from './Home';
import Services from './Services';
//import Testimonials from './Testimonials';
import Pricing from './Pricing';
import About from './About';
import Contact from './Contact';
import "./Routing.css";

class Routing extends Component {
    render(){
        return(
            <div className="Page-section">
                <canvas id='world'></canvas>
                <Switch>        
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/services" component={Services}/>
                    <Route exact path="/Pricing" component={Pricing}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/contact" component={Contact}/>
                </Switch>                
            </div>
        );
    }
}

export default Routing;
