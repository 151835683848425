import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import DrJanosElek from "./img/Dr_Janos_Elek.jpg";
import Sampling from "./img/PurpleSamples.jpg";

class About extends Component {
    render(){
        try {window.scroll({top: 0, left: 0, behavior: 'smooth'});}
        catch (error) {window.scrollTo(0, 0);}

        return(            
            <div>
                <h2 className="text-left">Science Port | <span className="glow"> About</span></h2>
                <div className="row mb-5">
                    <div className='col-11 col-md-8 col-lg-6 mx-auto'>
                        <div className="h4 mt-5 text-left highlightText">Dear Visitor,</div>                        
                    
                        <div className="h5 mt-5 mb-3 text-left highlightText">
                            As the manager of Science Port Ltd. let me welcome you to our webpage!
                        </div>
                                        
                        <div className="mt-4 mb-5 text-left wallOfText">
                            <p>If you’re looking for <span className='highlightText'>analytical services</span> – such as method development, spectral characterization or stability assessment, or if you simply have an analytical issue and you do not know how and where to begin <span className='highlightText'>problem solving</span>, you’re in the right place.</p>
                            <p>We are recognised as experts by our clients from the EU, Japan and the USA for solving analytical issues of all types.</p>
                        </div>
                    
                        <div className="col-10 col-md-8 col-lg-8 mt-1 mb-5 mx-auto">
                            <img className="rounded border border-white img-fluid" src={DrJanosElek} alt="Dr Janos Elek"/>
                        </div>
                    
                        <div className="mt-4 mb-5 text-left wallOfText">
                            <p>I’ve been a practicing analyst for <span className='highlightText'>25 years</span>, with a PhD in chemistry and as a laboratory analyst, university lecturer and industrial consultant.</p> 
                            <p>Science Port itself has been at the disposal of our clients since 2012, with a wide range of specialist analytical equipment available.</p> 
                            <p>We’re working in strong cooperation with experts – both from <span className='highlightText'>academy and industry</span> - of various fields in analytical chemistry, toxicology and registration.</p>
                            <p>Due to this cooperation we can offer a <span className='highlightText'>unique service</span> where a professional team guides you through the process of finding the most suitable analytical methods, to reporting; for meeting all scientific and regulatory requirements.</p>
                        </div>

                    <div className="col-10 col-lg-8 h4 mt-2 mb-2 mx-auto">
                        <Link className="navbar-brand" to="/contact">
                            <button type="button" className="btn btn-lg btn-outline-warning mb-1">Get in Touch</button>
                        </Link>                         
                    </div>      

                    <div className="mt-4 mb-5 text-left wallOfText">
                        <p>Our ‘basic’ service is for <span className='highlightText'>ECHA</span> spectral characterization, for pure organic and inorganic materials, UVCBs including plant extracts, and all types of chemistry.</p> 
                        <p>We understand the requirements for purity and impurity assessments.</p> 
                        <p>A very high percentage of chemical characterisation <span className='highlightText'>reports</span> are rejected by ECHA on grounds of quality/compliance; every one of our reports has been <span className='highlightText'>accepted</span>.</p>
                    </div>

                    <div className="col-10 col-sm-6 col-lg-4 mt-1 mb-5 mx-auto">
                        <img className="rounded border border-white img-fluid" src={Sampling} alt="Sample taking"/>
                    </div>

                    <div className="mt-4 mb-5 text-left wallOfText">
                        <p>But beyond these ‘basic’ registration requirements, we can provide you with evaluations from the best <span className='highlightText'>experts</span> for your custom chemical issues, for identification, impurity and stability assessments, by use of chromatographic, resonance and chemomertical approaches.</p>
                        <p>As a regular lecturer at the <a target="blank" href="www.unideb.hu">University of Debrecen</a> and as the Secretary of the Chemometrics and Cheminformatics Working group of the Academy of Hungarian Sciences, I’m in <span className='highlightText'>daily contact</span> with the best theoretical and practical chemists.</p>
                        <p>My personal <span className='highlightText'>experience</span> of working cooperatively with the pharmaceutical and chemical industries, I’m fully aware of the expectations of the industrial sector.</p>
                    </div>

                    <div className="mt-4 mb-5 text-left wallOfText">
                        So dear visitor please feel free to contact us and describe your analytical needs or problems, and we will make a <span className='highlightText'>free&nbsp;evaluation</span> for what we can offer to satisfactorily resolve them.
                    </div>

                    <div className="col-10 col-lg-8 h4 mt-2 mb-2 mx-auto">
                        <Link className="navbar-brand" to="/contact">
                            <button type="button" className="btn btn-lg btn-outline-warning mb-5">Get in Touch</button>
                        </Link>
                    </div>
                    <p>
                                            
                    </p> 

                    </div>
                                                      
                </div>            
            </div>
        );
    }
}

export default About;
