import React, {Component} from 'react';

class Footer extends Component {
    render(){
        return(
            <div className="mt-5">
            <footer className="mt-5 page-footer bg-dark text-secondary font-small fixed-bottom">
                <div className="footer-copyright text-center py-1">
                    <div>Science Port <span>Scientific Advisory Ltd.</span></div>
                    <div>&copy; 2019 Science Port Ltd. All Rights Reserved</div>                        </div>
            </footer>
            </div>
        );
    }
}

export default Footer;
