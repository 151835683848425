import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import "./Home.css";
//import SP from "./img/Logo.png";
import SP from "./img/Atom.png";

class Home extends Component {
    render(){
        try {window.scroll({top: 0, left: 0, behavior: 'smooth'});}
        catch (error) {window.scrollTo(0, 0);}

        return(
            <div id="home">
                <h2 className="text-left">Science Port | <span className="glow"> Home</span></h2>
                <img id="sp-img" src={SP} alt="SP logo"/>
                <div className="container">
                    <div className="row">                        

                        <div className="col-12 col-md-12 mt-5">
                            <span className="chemical-element">
                                <div className="desc topLeft">32.059</div>
                                S
                                <div className="desc topRight">
                                    -2
                                    +2
                                    +4
                                    +6
                                </div>
                                <div className="desc big bottomLeft-1">16</div>
                                <div className="desc bottomLeft-2">2-8-6</div>
                            </span>                                                    
                            <span className="col-7 col-md-11 title-1">cience</span>
                            <span className="chemical-element">
                                <div className="desc topLeft">30.9737</div>
                                P
                                <div className="desc topRight">
                                    -3
                                    +3
                                    +4
                                    +5
                                </div>
                                <div className="desc big bottomLeft-1">15</div>
                                <div className="desc bottomLeft-2">2-8-5</div>
                            </span>                                                    
                            <span className="col-7 col-md-11 title-1">ort</span>                            
                            <p className="col-12">
                                
                            </p>        
                            <div className="col-lg-10 mx-auto wallOfText text-left mt-5">
                                <h1 id="company-name" className="mt-2 highlightText">Scientific Advisory Ltd.</h1>
                                <h2 id="company-slogen">"The link between science and industry"</h2>
                            </div>
                            <div className="col-lg-10 mx-auto mt-4 mb-5 text-left" style={{fontSize: '1.2rem'}} >
                                We provide a professional analytical testing service, with a range of suitable analytical methods
                                to meet ECHA or other requirements, for almost any type of organic or inorganic test item.
                            </div>
                            <Link className="px-4" to="/contact">
                                <button type="button" className="btn btn-lg btn-outline-warning mb-5">Get in Touch</button>
                            </Link>
                            <Link className="px-4" to="/services">
                                <button type="button" className="btn btn-lg btn-outline-light mb-5">Our Services</button>
                            </Link>
                        </div>                         
                    </div>                
                </div>

                <div className="mb-3">
                    <h4 className="col-lg-10 mx-auto highlightText">Do not take our word for it, though - here is what our clients have to say</h4>
                </div>
            <div className="container"> 
                <div className="row justify-content-center mb-5 mx-auto">                    
                    <div className="card text-white bg-secondary col-lg-5 mx-1 mb-1">
                        <div className="card-body">
                            <div className="card-text text-left highlightText">
                                <p>"I work with Dr. Elek and Science Port for over {new Date().getFullYear() - 2014} years. In the beginning, Science port helped me and my clients with REACH/CLP relevant testing and later with specialised chemical analysis for certification of overseas transportation of certain ferroalloys under MARPOL rules and IMDG Code. Later assignments required non-standard innovative approach, which Dr. Elek truly delivered. </p>
                                <p>Our clients were very pleased by the timing, effectiveness and costs aspects of testing as well as by the responsiveness and positive attitude of personnel. The inspecting authorities were equally satisfied with the reliably of data and overall results of testing programs. </p>
                                <p>I would highly recommend Dr. Elek and Science Port for the challenging scientific testing work."</p>
                            </div>
                        </div>
                        <div className="card-header mb-5">
                            <h5 className="card-title  text-left">Igor Danilov,</h5>
                            <h6 className=" text-left">Counseil</h6>
                            <div className=" text-left">                                
                                <div>Dentons Europe LLC,</div>
                                <div>Brussels, Belgium</div>
                            </div>
                        </div>                                
                    </div>
                    
                    <div className="card text-white bg-secondary col-lg-5 mx-1 mb-1">
                        <div className="card-body">
                            <div className="card-text text-left highlightText">
                                <p>"I first met Janos Elek about  {new Date().getFullYear() - 2011}  years ago, I was immediately impressed that he understood very quickly the analytical requirements for GLP toxicology formulation sample analysis and was able to make very rapid suggestions on how to deal with some difficult analytical issues. </p>
                                <p>Since then I have been in close contact, discussing analytical projects for clients, methods under development and results of studies. These include GLP analysis for stability, homogeneity and concentration, as well as for non-GLP studies such as Chemical Characterisation, Structural Elucidation, Novel IR technology methods, and Identification of Unknown impurities. </p>
                                <p>In all cases, Janos and SciencePort gave a high quality scientific and practical solution to difficult and to routine issues. The depth of knowledge and enthusiasm for problem solving, together with the access to a very wide range of different types of analytical equipment, has resolved analytical problem that other labs have not achieved. </p>
                                <p>I am happy to recommend SciencePort to potential clients needing analytical support."</p>
                            </div>
                        </div>
                        <div className="card-header mb-5">
                            <h5 className="card-title text-left">David. J. Esdaile,</h5>
                            <h6 className=" text-left">Director of Science and Regulatory Affairs</h6>
                            <div className=" text-left">                                
                                <div>Citoxlab Hungary Ltd.</div>
                                <div>Veszprém, Hungary</div>
                            </div>
                        </div>                                
                    </div>
                    <div className="col-12 mt-2 mb-2">
                        <Link className="" to="/contact">
                            <button type="button" className="btn btn-lg btn-outline-warning mb-5">Get in Touch</button>
                        </Link>                        
                    </div>                      
                </div>  
                </div>               
            </div>
        );
    }
}

export default Home;
