import React, {Component} from 'react';
import "./Contact.css";
import Alembic from "./img/Alembic1.png";
import DrJanosElek from "./img/Dr_Janos_Elek.jpg";
import MateCsontos from "./img/matecsontos960.jpg";
import AnikoMezosi from "./img/anikomezosi960.jpg";
//import Alembic from "./img/Alembic2.png";
import "./Smoke.js";

class Contact extends Component {
    render(){
        try {window.scroll({top: 0, left: 0, behavior: 'smooth'});}
        catch (error) {window.scrollTo(0, 0);}

        return(
            <div className="Contact-page">
                <h2 className="text-left">Science Port | <span className="glow"> Contacts</span></h2>
                <div className="container">                
                    <div className="row align-items-baseline">
                        <div className="col-12 col-lg-6 text-center">
                            <h3 className='highlightText mt-3 mb-1'>How can SciencePort support your business?</h3>
                            <img className="Alembic-img mb-1" src={Alembic} alt="Alembic"/>
                        </div>
                        
                        <div className="col-12 col-lg-6 text-center">
                            <h3 className='highlightText mt-1 mb-2'>Contact us to find out together!</h3>
                            <div className="card text-white bg-secondary mb-3">
                                <div className="card-header text-left">
                                    <h4 className="highlightText">Contact</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4">
                                            <img className="rounded border border-white img-fluid" src={DrJanosElek} alt="Dr Janos Elek"/>
                                        </div>
                                        <div className="col-8 text-left">
                                            <div className="col-12 card-title h5">Dr. Janos Elek</div>
                                            <div className="col-12 highlightText"><i className="fas fa-user-alt"></i> Bussiness Owner</div>
                                            <div className="col-12 highlightText"><i className="fas fa-envelope"></i><a href="mailto:elek@scienceport.hu"> elek@scienceport.hu</a></div>
                                            <div className="col-12 highlightText"><i className="fas fa-mobile-alt"></i> +36 (30) 535-1131</div>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                            <h3 className='highlightText mt-4 mb-4'>Please do not hesitate to call or mail us, our team is happy to receive your enquiry!</h3>
                        </div>
                        <div className="col-12 col-lg-6 text-center mb-lg-5">
                            <div className="card text-white bg-secondary mb-4">
                                <div className="card-header text-left">
                                    <h4 className="highlightText">Contact</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4">
                                            <img className="rounded border border-white img-fluid" src={MateCsontos} alt="Mate Csontos"/>
                                        </div>
                                        <div className="col-8 text-left">
                                            <div className="col-12 card-title h5">Máté Csontos</div>
                                            <div className="col-12 highlightText"><i className="fas fa-user-alt"></i> Office Manager</div>
                                            <div className="col-12 highlightText"><i className="fas fa-envelope"></i><a href="mailto:info@scienceport.hu"> info@scienceport.hu</a></div>
                                            <div className="col-12 highlightText"><i className="fas fa-mobile-alt"></i> +36 (70) 625-1409</div>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 text-center mb-4">
                            <div className="card text-white bg-secondary">
                                <div className="card-header text-left">
                                    <h4 className="highlightText">Contact</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4">
                                            <img className="rounded border border-white img-fluid" src={AnikoMezosi} alt="Aniko Mezosi"/>
                                        </div>
                                        <div className="col-8 text-left">
                                            <div className="col-12 card-title h5">Anikó Mezősi</div>
                                            <div className="col-12 highlightText"><i className="fas fa-user-alt"></i> Project Manager</div>
                                            <div className="col-12 highlightText"><i className="fas fa-envelope"></i><a href="mailto:aniko.mezosi@scienceport.hu"> aniko.mezosi@scienceport.hu</a></div>
                                            <div className="col-12 highlightText"><i className="fas fa-mobile-alt"></i> +36 (70) 674-2992</div>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>    
            </div>
        );
    }
}

export default Contact;
