import React, {Component} from 'react';
import {NavLink, Link} from 'react-router-dom';
import Logo from "./img/Logo.png";

import './Navbar.css';

class Navbar extends Component {
    constructor(props) {
        super(props);        
        this.state = {collapsed: true,};
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.collapseNavbar = this.collapseNavbar.bind(this);
    }
    toggleNavbar() {        
        this.setState({collapsed: !this.state.collapsed,});        
    }    
    collapseNavbar() {        
        this.setState({collapsed: true,});        
    }    
    render(){
        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        const classSpacer = collapsed ? 'rightSpacer' : '';
 
        return(
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
                <Link className="navbar-brand" to="/">
                    <img src={Logo} alt="logo"/>
                </Link>                                  
                <button 
                    onClick={this.toggleNavbar}
                    className={`navbar-toggler ${classTwo}`}                    
                    type="button" 
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"/>                    
                </button>
                <div className={`collapse navbar-collapse ${classOne}`} id="navbarNav">
                    <ul className="navbar-nav">                    
                        <li className="nav-item">
                            <NavLink onClick={this.collapseNavbar} className={`nav-link ${classSpacer}`} exact activeClassName="active-link" to="/about">About </NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink onClick={this.collapseNavbar} className={`nav-link ${classSpacer}`} exact activeClassName="active-link" to="/services">Services </NavLink>
                        </li>                        
                        
                        <li className="nav-item">
                            <NavLink onClick={this.collapseNavbar} className="nav-link" exact activeClassName="active-link" to="/pricing">Pricing </NavLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">                        
                        <li className="nav-item">
                            <NavLink onClick={this.collapseNavbar} className="nav-link" exact activeClassName="active-link" to="/contact">Contact us </NavLink>
                        </li>
                    </ul>
                </div>

                </nav> 
            </div>
        );
    }
}

export default Navbar;
